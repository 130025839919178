<template>
  <div class="modal" style="display:block">

    <div class="modal-content" v-bind:class="{ 'bravo' : hasError === false}">
        <!-- question -->
        <p v-if="hasError === null && showEcranFin === null && showEcranDebut === null" v-html="current.libelle">{{ current.libelle }}</p>

        <div v-if="hasError === true">
          <p><strong><big class="red">PAS TOUT A FAIT !</big></strong> <br>
          <span v-html="current.messages.erreur"></span></p>
        </div>
        <div v-if="hasError === false && showEcranFin === null && showEcranDebut === null">
          <h2>BRAVO !</h2>
          <p><span v-html="current.messages.bravo"></span></p>
        </div>

        <!-- écrans de début avec des images avant de débuter le quiz -->
        <div v-if="showEcranDebut !== null">
            <template v-for="ecranDebut, index in current.ecransDebut">
                <template v-if="showEcranDebut === index">
                    <p v-html="ecranDebut.titre" :key="'p'+index"></p>
                    <div :key="index" :class="(ecranDebut.class !== undefined) ? ecranDebut.class : 'visuel'">
                        <!-- <p class="blue" v-html="ecranDebut.titre"></p> -->
                        <img :src="ecranDebut.image"
                        :alt="ecranDebut.alt">
                    </div>
                </template>
            </template>
        </div>

        <!-- écrans de fin avec des images après un quiz, avant de passer à la suite -->
        <div v-if="showEcranFin !== null">
          <template v-for="ecranFin, index in current.ecransFin">
            <template v-if="showEcranFin === index">
              <!-- <p v-html="ecranFin.titre" :key="'p'+index"></p> -->
              <div class="visuel" :key="index">
                <p class="blue" v-html="ecranFin.titre"></p>
                <img :src="ecranFin.image"
                  :alt="ecranFin.alt">
              </div>
            </template>
          </template>
        </div>

        <!-- réponses -->
        <template v-if="showEcranFin === null && showEcranDebut === null">
          <ul class="options" :class="current.class">
            <li v-for="(reponse, index) in current.reponses" :key="index">
              <span class="option" v-html="reponse.libelle">{{ reponse.libelle }}</span>
              <button type="button" class="btn defaut"
                :id="'vrai'+index"
                :disabled="hasError === false"
                @click="clicVraiFaux($event)">Vrai</button>
              <button type="button" class="btn defaut"
                :id="'faux'+index"
                :disabled="hasError === false"
                @click="clicVraiFaux($event)">Faux</button>
            </li>
          </ul>
        </template>

        <p v-if="hasError === false" class="legende" v-html="current.messages.legende"></p>
    </div>

    <!-- MODAL FOOTER -->
    <div class="modal-footer">

        <button v-if="hasError === null && (showEcranDebut !== null && showEcranDebut < current.ecransDebut.length -1)" type="button" class="btn btn-blue align-right" @click="afficheEcransDebut()"><span>Suivant</span></button>
        <button v-if="hasError === null && (showEcranDebut !== null && showEcranDebut == current.ecransDebut.length -1)" type="button" class="btn btn-blue align-center" @click="afficheEcransDebut()"><span>Faire le test</span></button>

        <button v-if="hasError !== false && showEcranDebut === null" type="button" class="btn btn-blue align-center" @click="checkSelectedReponses()"><span>Valider</span></button>

        <button v-if="hasError === false && (current.ecransFin === undefined || showEcranFin === current.ecransFin.length -1) && (current.ajoutDossier === undefined || isAlreadyInDossier)" type="button" class="btn btn-blue align-right" @click="ecranSuivant()"><span>Suivant</span></button>

        <button v-if="hasError === false && (current.ecransFin !== undefined && (showEcranFin === null || showEcranFin < current.ecransFin.length -1))" type="button" class="btn btn-blue align-right" @click="afficheEcransFin()"><span>Suivant</span></button>

        <button v-if="hasError === false && current.ajoutDossier !== undefined && !isAlreadyInDossier && showEcranFin === current.ecransFin.length -1" type="button" class="btn btn-blue align-center" @click="addToDossier()"><span>Ajouter aux indices</span></button>
    </div>
  </div>
</template>


<script>
import jsonQuiz from '@/data/quiz.json'
import { EventBus } from '@/core/event-bus.js';

export default {
	name: 'QuizVraiFaux',
  data() {
    return {
      quizList: jsonQuiz,
      currentQuestion: 6, // on a un seul quiz de ce type
      hasError: null,
      nbBonnesReponsesSelected: 0,
			showEcranDebut: null,
			showEcranFin: null,
			isAlreadyInDossier: false
    }
  },
  mounted() {
      if (this.current.ecransDebut !== undefined) {
        this.showEcranDebut = 0
      }
  },
  computed: {
    current() {
      return this.quizList[this.currentQuestion]
    },
    bonnesReponsesIds() {
      let bonnes_reponses = [];

      for (let i = 0; i < this.current.reponses.length; i++) {
          bonnes_reponses.push(this.current.reponses[i].vrai);
      }

      return bonnes_reponses
    }
  },
	methods : {
		close() {
			this.$emit('close')
		},

    clicVraiFaux(e) {
      let rep = e.target
      let rep_id = rep.getAttribute('id')
      let other = null

      if (rep_id.indexOf('vrai') !== -1) {
        other = rep.nextElementSibling
      } else {
        other = rep.previousElementSibling
      }

      rep.classList.remove('defaut')
      rep.classList.add('selected')
      other.classList.remove('selected')
      other.classList.add('defaut')
    },

    checkSelectedReponses() {
      this.hasError = null

      // on parcourt les reponses selectionnees
      let selected = document.getElementsByClassName('selected')
      for (let j=(selected.length - 1); j>=0; j--) {

        // index de la reponse
        let rep_id = selected[j].getAttribute('id')
        let vrai_ou_faux = (rep_id.substr(0, 4) == 'vrai') ? true : false
        let rep_index = parseInt(rep_id.substr(4, rep_id.length),10)

        // si la réponse sélectionnée est bonne
        if ( this.bonnesReponsesIds[rep_index] == vrai_ou_faux) {

          let other = (vrai_ou_faux == true) ? selected[j].nextElementSibling : selected[j].previousElementSibling
          other.disabled = true

          selected[j].classList.add('right')
          selected[j].classList.remove('selected')
          this.nbBonnesReponsesSelected++

        } else {
          selected[j].classList.add('wrong')
          selected[j].classList.remove('selected')
        }
      }

      // s'il manque des bonnes réponses
      if (this.nbBonnesReponsesSelected != this.bonnesReponsesIds.length) {
        this.hasError = true
      } else {

        // toutes les bonnes réponses OK
        this.hasError = false

        // on reset la couleur des mauvaises reponses pour ne laisser que les bonnes reponses en vert
        let mauvaises_reponses = document.getElementsByClassName('wrong')
        for (let j=(mauvaises_reponses.length - 1); j>=0; j--) {
          mauvaises_reponses[j].classList.add('defaut')
          mauvaises_reponses[j].classList.remove('wrong')
        }
      }
    },

		afficheEcransDebut() {
			if (this.showEcranDebut === null) {
				this.showEcranDebut = 0
			} else {
				this.showEcranDebut++
			}

      if (this.showEcranDebut == this.current.ecransDebut.length) {
        this.showEcranDebut = null
      }
		},

		afficheEcransFin() {
			if (this.showEcranFin === null) {
				this.showEcranFin = 0
			} else {
				this.showEcranFin++
			}
		},

		addToDossier() {
			this.$store.commit("addToDossier", {dossier: this.current.ajoutDossier.dossier, item: this.current.ajoutDossier.item})
			this.isAlreadyInDossier = true

			// fix pour pouvoir laisser le quiz declenché auto de se lancer si c'est aussi un QCM
			// on ferme pour que ça puisse se rouvrir auto
			// ça ne pose pas de probleme de fermer vu que l'ajout au dossier se fait à la toute fin du quiz
			this.$emit('close')
		},

    ecranSuivant() {
      if (this.current.enchaineQuizIndex != undefined) {

        let nextQuiz = this.quizList[this.current.enchaineQuizIndex]
        EventBus.$emit('changeQuiz', nextQuiz.type, nextQuiz.index);

      } else {
        this.close()
      }
    }
	}
}
</script>


<style scoped lang="scss">
    @import "@/assets/css/_variables.scss";

    .fullscreen {
        width: calc(100% + 7rem);
        margin-left: -50%;
        left: calc(50% - 3.5rem);
        position: relative;
        top: -5rem;

        img {
            max-width: 100%;
        }
    }
</style>